<template lang="">
    <div>
        <v-card lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h6">{{ $t('cms.add_course_to_folder') }}</span>
                <button type="button" class="btn-close" @click="onClickClose">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text class="py-5">
                <TargetCourse :coursesAssigned="coursesAssigned" :isHideFolder="true" />
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn color="blue darken-1" text @click="onClickClose">{{ $t('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="onClickSave"> {{ $t('form.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import TargetCourse from '../TargetCourse';
import { FolderItem } from '../../../api';

export default {
    props: {
        isOpen: { type: Boolean, required: true },
        onAdd: { type: Function, required: true },
        onClose: { type: Function, required: true },
    },
    components: {
        TargetCourse,
    },
    data() {
        return {
            coursesAssigned: [],
        };
    },
    created() {
        this.getCourseAssigned();
    },
    watch: {
        '$props.isOpen': function () {
            if (this.isOpen) {
                this.getCourseAssigned();
            }
        },
    },
    methods: {
        async getCourseAssigned() {
            try {
                const folder_id = this.$route.query.folder_id;
                const res = await FolderItem.getList({
                    fields: ['id', 'user_id', 'course_id', { course: ['id', 'title'] }],
                    where: { folder_id, user_id: { $is: null } },
                });
                this.coursesAssigned = res?.results?.objects?.rows?.map((item) => item?.course);
            } catch (error) {
                console.log('🚀 ~ file: AddCourseToFolder.vue:61 ~ getCourseAssigned ~ error:', error);
            }
        },

        onClickClose() {
            this.coursesAssigned = [];
            this.onClose();
        },

        onClickSave() {
            const idList = this.coursesAssigned.map((item) => item?.id);
            this.onAdd(idList, (isSuccess) => {
                if (isSuccess) this.onClickClose();
            });
        },
    },
};
</script>

<style scoped lang="scss"></style>
