<template lang="">
    <div class="targetCourse">
        <div class="targetCourse__top">
            <h5>{{ $t('cms.target_course') }}</h5>
            <a-input-search :placeholder="$t('header.search')" @change="handleSearch" />
        </div>
        <div class="targetCourse__content">
            <div class="targetCourse__left">
                <!-- COURSE LIST -->
                <div class="targetCourse__courseList">
                    <a-checkbox-group :value="coursesChecked">
                        <div
                            class="targetCourse__courseItem"
                            v-for="(course, index) in coursesObject.rows"
                            :key="index"
                        >
                            <a-checkbox :value="course.id" @change="handleCheckedCourse">
                                <v-icon class="fa-solid fa-book-open"></v-icon>
                                <span>{{ course.title }}</span>
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                    <div class="targetCourse__loading" v-if="isLoading">
                        <a-spin />
                    </div>
                    <div
                        class="targetCourse__loadMore"
                        @click="handleMoreCourse"
                        v-if="!isLoading && coursesObject.count > coursesObject.rows.length"
                    >
                        <button>{{ $t('load_more') }}</button>
                    </div>
                </div>
            </div>
            <div class="targetCourse__right">
                <div :style="{ minHeight: '100%' }">
                    <div class="targetCourse__checked" v-for="(course, index) in coursesChoosed" :key="index">
                        <button type="button" @click="handleCancelChecked(course.id)">
                            <v-icon class="fa-solid fa-trash"></v-icon>
                        </button>
                        <span>{{ course.title }}</span>
                    </div>
                </div>
                <div class="targetCourse__clearAll" v-if="coursesChoosed.length > 0">
                    <button type="button" @click="handleClearAll">{{ $t('cms.clear_all') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _ from 'lodash';
import { Course } from '../../../api';

export default {
    props: {
        coursesAssigned: {
            type: Array,
            required: true,
        },
        isHideFolder: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            isLoading: false,
            currentPageCourse: 1,
            searchValue: '',
            coursesObject: {
                count: 0,
                rows: [],
            },
            coursesChecked: [],
            coursesChoosed: [],
        };
    },
    async created() {
        await this.getCourses();
        this.coursesChoosed = this.coursesAssigned;
        this.coursesChecked = this.coursesChoosed.map((item) => item.id);
    },
    watch: {
        '$props.coursesAssigned': function () {
            this.coursesChoosed = this.coursesAssigned;
            this.coursesChecked = this.coursesChoosed.map((item) => item.id);
        },
    },
    methods: {
        async getCourses() {
            this.isLoading = true;
            try {
                let params = {
                    fields: ['id', 'title'],
                    order: [['created_at', 'desc']],
                    page: this.currentPageCourse,
                    limit: 10,
                };

                if (this.searchValue) {
                    params.where = { title: { $iLike: `%${this.searchValue}%` } };
                }

                const res = await Course.getList(params);
                const { count, rows } = res?.results?.objects;
                if (this.currentPageCourse === 1) {
                    this.coursesObject = { count, rows };
                } else {
                    this.coursesObject = {
                        count,
                        rows: this.coursesObject.rows.concat(rows),
                    };
                }
            } catch (error) {
                console.log('🚀 ~ file: index.vue:120 ~ getCourses ~ error:', error);
            }
            this.isLoading = false;
        },

        handleMoreCourse() {
            this.currentPageCourse = this.currentPageCourse + 1;
            this.getCourses();
        },

        handleCheckedCourse(e) {
            const courseID = e.target.value;
            const findIndexID = this.coursesChecked.findIndex((id) => id === courseID);

            if (findIndexID === -1) {
                this.coursesChecked.push(courseID);

                const findCourse = this.coursesObject.rows.find((course) => course.id === courseID);
                if (findCourse) {
                    this.coursesChoosed.push({
                        id: findCourse.id,
                        title: findCourse.title,
                    });
                }
            } else {
                this.coursesChecked.splice(findIndexID, 1);

                const findIndexCourse = this.coursesChoosed.findIndex((course) => course.id === courseID);
                this.coursesChoosed.splice(findIndexCourse, 1);
            }
        },

        handleCancelChecked(courseID) {
            const findIndexCourse = this.coursesChoosed.findIndex((course) => course.id === courseID);

            if (findIndexCourse !== -1) {
                this.coursesChoosed.splice(findIndexCourse, 1);

                const findIndexID = this.coursesChecked.findIndex((id) => id === courseID);
                this.coursesChecked.splice(findIndexID, 1);
            }
        },

        handleClearAll() {
            this.coursesChoosed.splice(0, this.coursesChoosed.length);
            this.coursesChecked = [];
        },

        handleSearch: _.debounce(function (e) {
            this.searchValue = e.target.value.trim();
            this.currentPageCourse = 1;
            this.getCourses();
        }, 500),
    },
};
</script>

<style lang="scss">
.targetCourse {
    border: 1px solid #ccc;
    & h5 {
        margin-bottom: 0;
    }
}

.targetCourse__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
    & .ant-input-search {
        width: 100%;
        max-width: 350px;
    }
}

.targetCourse__content {
    display: flex;
}

.targetCourse__left {
    width: 50%;
    min-height: 100%;
    border-right: 1px solid #ccc;
    & .ant-tabs-nav {
        width: 100%;
    }
    & .ant-tabs-nav .ant-tabs-tab {
        width: 50%;
        margin: 0;
        text-align: center;
    }
    & .ant-tabs-ink-bar {
        width: 50% !important;
    }
}

.targetCourse__right {
    position: relative;
    width: 50%;
}

.targetCourse__courseList {
    max-height: 500px;
    overflow-y: scroll;
}

.targetCourse__right {
    min-height: 100%;
    max-height: 460px;
    overflow-y: scroll;
}

.targetCourse__courseItem {
    padding: 10px;
    & i {
        margin-right: 5px;
        font-size: 18px !important;
    }
}

.targetCourse__loading,
.targetCourse__loadMore {
    padding: 15px;
    text-align: center;
    & button {
        color: #1890ff;
    }
}

.targetCourse__checked {
    padding: 10px 20px;
    & button i {
        margin-right: 10px;
        font-size: 18px !important;
        transition: all 0.3s ease;
    }
    & button:hover i {
        color: red;
    }
}

.targetCourse__clearAll {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 -5px 10px rgba($color: #000000, $alpha: 0.05);
    z-index: 1;
    & button {
        transition: all 0.4s ease;
    }
    & button:hover {
        color: red;
    }
}
</style>
