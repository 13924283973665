<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Course') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="folderItems"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                            :loading="isLoadingFolderItem"
                            :loading-text="$t('cms.loading')"
                            class="elevation-1"
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogAdd" persistent max-width="1000px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mr-2" @click="onAddNewItem()" small>
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                        </template>

                                        <AddCourseModal
                                            :isOpen="dialogAdd"
                                            :onAdd="onAddCourse"
                                            :onClose="onCloseAdd"
                                            v-if="dialogAdd"
                                        />
                                    </v-dialog>

                                    <v-dialog v-model="dialogEdit" max-width="1000px">
                                        <EditCourseModal
                                            :cuItem="cuItem"
                                            :closeCU="onCloseEdit"
                                            :save="onEditCourse"
                                            :chapters="chapters"
                                            :dialogEdit="dialogEdit"
                                        >
                                        </EditCourseModal>
                                    </v-dialog>

                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="onCloseDelete" :deleteItemConfirm="onDeleteCourse">
                                        </ModalDelete>
                                    </v-dialog>

                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.thumbnail`]="{ item }">
                                <div class="my-2">
                                    <v-img :src="item.thumbnail || noImage" alt="" class="table-image" />
                                </div>
                            </template>
                            <template v-slot:[`item.title`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.note`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.note || '-' }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark class="mr-2" small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <router-link :to="{ path: `/record-of-course/${item.id}` }">
                                    <v-btn color="indigo" class="mr-2" dark small>
                                        <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                        &nbsp;{{ $t('cms.everyone_course_status') }}
                                    </v-btn>
                                </router-link>
                                <v-btn color="info" class="mr-2" small @click="onEditItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="folderItemsPagination.pageSize"
                                :totalItems="folderItemsPagination.totalItems"
                                :disabled="isLoadingFolderItem"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import * as _ from 'lodash';
import { mapState, mapActions } from 'vuex';

import { CRUD } from '../../constants';
import { mixinFormats, mixinToasts, mixinLoadings } from '../../mixins';

import AddCourseModal from '../../components/CMS/Modal/AddCourseToFolder.vue';
import EditCourseModal from '../../components/CMS/Modal/Course.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';

export default {
    mixins: [mixinFormats, mixinToasts, mixinLoadings],
    components: { AddCourseModal, EditCourseModal, ModalDelete },
    data() {
        return {
            // TABLE VARIABLES
            tableColumns: [
                { text: this.$t('form.image'), value: 'thumbnail', width: '100px', sortable: false },
                { text: this.$t('form.name'), value: 'title', width: '250px', sortable: false },
                { text: this.$t('form.administrator_note'), value: 'note', width: '300px', sortable: false },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    width: '200px',
                    sortable: false,
                },
                {
                    text: this.$t('form.updated_at'),
                    value: 'updated_at_unix_timestamp',
                    width: '200px',
                    sortable: false,
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '450px' },
            ],
            page: 1,
            noImage: require('../../assets/images/no_image.jpg'),

            // DIALOG VARIABLES (TYPE BOOLEAN)
            dialogAdd: false,
            dialogEdit: false,
            dialogDelete: false,

            // SEARCH VARIABLES
            fieldsSearch: [
                {
                    title: this.$t('form.title'),
                    value: 'title',
                },
            ],
            selectedField: 'title',
            search: '',

            // OTHER VARIABLES
            cuIndex: -1,
            cuItem: {},
        };
    },
    computed: {
        ...mapState('folderItem', ['isLoadingFolderItem', 'folderItems', 'folderItemsPagination']),
        ...mapState('chapter', ['chapters']),

        formTitle() {
            return this.cuIndex === -1 ? this.$t('cms.new_item') : this.$t('cms.edit_item');
        },
    },
    async mounted() {
        await this.getCourses({ page: this.page, field: this.selectedField, search: this.search });
        await this.getChaptersSections({ page: 1, field: 'title', search: '', limit: 9999 });
    },
    watch: {
        page(val) {
            this.getCourses({ page: val, field: this.selectedField, search: this.search });
        },
        $route: function () {
            this.getCourses({ page: 1, field: this.selectedField, search: this.search });
        },
    },
    methods: {
        ...mapActions('folderItem', ['getItemsFromFolder', 'createMultiItem', 'putItem', 'deleteItem']),
        ...mapActions('chapter', ['getChaptersSections']),
        ...mapActions('course', ['getCoursesOne']),

        // -------------------- FUNCTION SHOW / HIDDEN DIALOG ---------------------
        onAddNewItem() {
            this.dialogAdd = true;
        },

        async onEditItem(item) {
            this.dialogEdit = true;
            this.cuIndex = this.folderItems.indexOf(item);
            this.cuItem = Object.assign({}, item);
            await this.getCoursesOne(item?.id);
        },

        onDeleteItem(item) {
            this.dialogDelete = true;
            this.cuIndex = this.folderItems.indexOf(item);
            this.cuItem = Object.assign({}, item);
        },

        onCloseAdd() {
            this.dialogAdd = false;
        },

        onCloseEdit() {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.cuIndex = -1;
                this.cuItem = {};
            });
        },

        onCloseDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuIndex = -1;
                this.cuItem = {};
            });
        },
        // ------------------------------------------------------------------------

        // -------------------- FUNCTION SHOW / HIDDEN DIALOG ---------------------
        async getCourses(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field || 'title';
            this.search = data?.search || '';
            const folder_id = this.$route.query.folder_id;
            const res = await this.getItemsFromFolder({ ...data, folder_id, type: 'COURSE' });

            if (!res?.isSuccess) {
                this.showError(res?.mess);
            }
        },
        // ------------------------------------------------------------------------

        // -------------------- FUNCTION ADD, EDIT AND DELETE ---------------------
        async onAddCourse(courseIdList, callback) {
            const folder_id = this.$route.query.folder_id;
            const body = { folder_id, type: 'COURSE', items: courseIdList };

            this.showLoading();
            const res = await this.createMultiItem(body);

            callback(res?.isSuccess);
            if (res?.isSuccess) {
                await this.getCourses({ page: 1, field: 'title', search: '' });
                this.showSuccess(CRUD.CREATE);
            } else {
                this.showError(res?.mess);
            }
            this.hideLoading();
        },

        async onEditCourse(course__settings, callback) {
            this.showLoading();
            const body = {
                ...this.cuItem,
                array_hashtag: this.cuItem?.array_hashtag?.map((item) => item.replace(/\s/g, '')),
                course_settings: course__settings,
                materials: this.cuItem?.materials,
                type: 'COURSE',
            };
            const res = await this.putItem(body);
            callback(res?.isSuccess);
            if (res?.isSuccess) {
                await this.getCourses({ page: this.page, field: this.selectedField, search: this.search });
                this.showSuccess(CRUD.UPDATE);
            } else {
                this.showError(res?.mess);
            }
            this.hideLoading();
        },

        async onDeleteCourse() {
            this.onCloseDelete();
            this.showLoading();

            const res = await this.deleteItem(this.cuItem?.item_folder_id);

            if (res?.isSuccess) {
                await this.getCourses({ page: this.page, field: this.selectedField, search: this.search });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.mess);
            }
            this.hideLoading();
        },
        // ------------------------------------------------------------------------

        // -------------------- FUNCTION SEARCH ---------------------
        onSearchItem: _.debounce(function (e) {
            this.getCourses({ page: 1, field: this.selectedField, search: this.search });
        }, 500),

        handleChangeSelect(value) {
            this.getCourses({ page: 1, field: value, search: this.search });
        },
        // ----------------------------------------------------------
    },
};
</script>

<style lang="scss">
.table-image {
    width: 45px;
    height: 45px;
}

.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}
</style>
