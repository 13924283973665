<template lang="">
    <div class="breadcrumbFolder mx-5 my-2">
        <a-breadcrumb separator=">" v-if="breadcrumbList.length > 0">
            <a-breadcrumb-item>
                <router-link :to="{ path: pathLink }" class="breadcrumbFolder__link">
                    {{ firstTitle }}
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
                <span v-if="index === breadcrumbList.length - 1">{{ item.title }}</span>
                <router-link
                    :to="{ path: pathLink, query: { folder_id: item.id } }"
                    class="breadcrumbFolder__link"
                    v-else
                >
                    {{ item.title }}
                </router-link>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<script>
import { Folder } from '../../../api';

export default {
    props: {
        firstTitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbList: [],
            pathLink: this.$route.path,
        };
    },
    async created() {
        await this.getBreadcrumbs();
    },
    watch: {
        '$route.query.folder_id': function () {
            this.getBreadcrumbs();
        },
    },
    methods: {
        async getBreadcrumbs() {
            const folder_id = this.$route.query.folder_id;
            if (folder_id) {
                try {
                    const res = await Folder.getListParent(folder_id);
                    this.breadcrumbList = res?.results?.object;
                } catch (error) {
                    console.log('🚀 ~ file: index.vue:43 ~ getBreadcrumbs ~ error:', error);
                }
            } else {
                this.breadcrumbList = [];
            }
        },
    },
};
</script>

<style lang="scss">
.breadcrumbFolder {
    & a,
    & .ant-breadcrumb-link {
        font-size: 15px;
    }
    & a.breadcrumbFolder__link {
        color: #007fff !important;
    }
}
</style>
