<template>
    <v-form ref="form" @submit.prevent="clickSave">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="modal__title">
                <span class="text-h5">{{ dialogEdit ? $t('action.edit_folder') : $t('action.new_folder') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                ref="Title"
                                v-model="cuItem.title"
                                :label="$t('form.title')"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }}</v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mixinRules } from '../../../mixins/rules';

export default {
    mixins: [mixinRules],
    components: {},
    props: {
        cuItem: {
            type: Object,
        },
        formTitle: {
            type: String,
        },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            items: [],
            errorMessages: '',
            formHasErrors: false,
        };
    },
    computed: {
        form() {
            return {
                title: this.cuItem.title,
            };
        },
    },
    methods: {
        validate() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },

        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },

        clickSave() {
            const isValid = this.$refs.form.validate();

            if (isValid) {
                this.save((isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            }
        },

        clickCancel() {
            this.resetForm();
            this.closeCU();
        },
    },
};
</script>

<style lang="scss">
.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;

    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }

    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
